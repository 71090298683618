<template>
  <div class="wrapper">

    <div class="body" style="padding:0;">

      <Slider></Slider>

      <div class="cas-header-wrapper">

        <div class="se-wrapper py-2 px-2">
          <div class="input-group"
            style="border: 1px black solid;border-radius: 5px;background-color: white;color: black;width: 100%;height: 40px;text-align: center;">
            <input @keyup="keyUpSearch" @keyup.enter="performSearch" type="text" class="form-control" id="search-input"
              name="keyword" placeholder="Search for casino games" aria-label="Search for games, leagues, events"
              v-model="to_search">
            <div @click="performSearch" class="input-group-prepend"
              style="border-radius: 5px;background-color: white;color: black;text-align: center;">
              <button style="background-color: var(--yellow);" class="btn btn-sm" type="button">
                <img style="width: 30px;" src="/img/home/icon-search.png">
              </button>
            </div>
          </div>

        </div>
        <div class="catsec pb-3 pt-1" id="catsec">
          <span v-for="(item, index) in categories" class="cattit" @click="getCasinoGames(item)" :class="isActive(item)"
            v-bind:key="getKey(index)">
            <img style="width: 25px;" :src="categoryImages[index]" alt="category image" class="category-image">
            {{ item.name }}
          </span>
        </div>
        <div style="height: 35px;" class="marquee-container">
          <marquee behavior="scroll" direction="left" scrollamount="12"  onmouseover='this.stop()' onmouseout='this.start()'>
            <span style="color: var(--yellow);margin-right: 200px;">
              <img style="width: 20px;" src="/img/icons-trophy.png" />
              254****67 won 25Ksh. JetX
            </span>

            <span style="color: var(--yellow);margin-right: 200px;">
              <img style="width: 20px;" src="/img/icons-trophy.png" />
              254****75 won 320Ksh. Aviator
            </span>

            <span style="color: var(--yellow);margin-right: 200px;">
              <img style="width: 20px;" src="/img/icons-trophy.png" />
              254****96 won 63Ksh. Comet crash
            </span>
          </marquee>
          <!-- <p style="color: var(--yellow);" class="marquee-text">
            <span><img style="width: 20px;" src="/img/icons-trophy.png" />254****67 won 25Ksh. JetX</span>
            <span><img style="width: 20px;" src="/img/icons-trophy.png" />254****75 won 320Ksh. Aviator</span>
            <span><img style="width: 20px;" src="/img/icons-trophy.png" />254****75 won 63Ksh. Comet crash</span>
          </p> -->
        </div>
      </div>
      <ShimmerCasino v-if="loading"></ShimmerCasino>

      <div class="row m-0 p-0" style="background-color: #1c2243">

        <div v-show="searchgamesdata.length > 0" class="casino-headers " style="">
          <span>Search Results</span>
        </div>

        <!-- new card design -->
        <div v-show="searchgamesdata.length > 0" class="row m-0 p-0 casino-row" v-for="(o, index) in searchgamesdata"
          v-bind:key="getKey(index)">
          <div class="game-icon casino-card col-lg-3 col-md-3 col-sm-3 col-3 m-0 p-0">
            <a>
              <img loading="lazy" :src="getImg(o.image)" alt="Avatar" class="game-card" data-toggle="modal"
                style="border: solid var(--light-gray) 1px;" :data-target="'#searchCasinoGames' + o.game_id" />
            </a>
          </div>

          <div class="modal fade" :id="'searchCasinoGames' + o.game_id" tabindex="-1" role="dialog"
            :aria-labelledby="'searchCasinoGamesLabel' + o.game_id" aria-hidden="true">
            <div class="modal-dialog modal-dialog" role="document">
              <div style="color: var(--buttonBackgroundColor);" class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" :id="'searchCasinoGamesLabel' + o.game_id">{{ o.game_name }}</h5>
                  <span style="color: var(--buttonBackgroundColor);" type="button" class="close" id="faso-close"
                    data-dismiss="modal">&times;</span>
                </div>
                <div class="modal-body">
                  <img loading="lazy" style="border-radius: 5px;border: solid var(--light-gray) 1px;" :src="o.image"
                    alt="gameimg" class="ww-100 mb-2" />
                  <div class="text-center">
                    <router-link data-dismiss="modal"
                      style="background-color: var(--buttonBackgroundColor);color: var(--darkBackground);"
                      class="btn ww-100 mb-2"
                      :to="{ name: 'casinogames', params: { IsDemo: 0, providerID: o.provider_id, gameName: o.game_name, gameID: o.game_id, launchURL: o.launch_url, launchType: o.launch_type, launchParameters: o.launch_parameters }, }">
                      Play Now
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- new card design -->
      </div>

      <div id="scroller">

        <div v-show="searchgamesdata.length == 0" class="row m-0 p-0 pb-5" style="background-color: #293746">
          <!-- Left sidebar -->
          <div class="col-lg-2 d-none d-lg-block p-2">
            <div class="promotion-sidebar mb-2"
              style="background-color: #1c2243; color: white; padding: 10px; border-radius: 10px;height: 100%;">
              <div class="text-center">
                <router-link style="background-color: #1c2243; color: var(--buttonBackgroundColor); width: 47%;"
                  class="btn" to="/my-account">
                  <img style="width: 40px;" src="/img/menu/user-icon.png" />
                </router-link>
                <router-link
                  style="background-color: var(--buttonBackgroundColor); color: var(--darkBackground); margin-right: 5px; width: 47%;text-align: left;"
                  class="btn" to="/my-account">
                  <span style="font-size: 13px;">ACCOUNT</span>
                </router-link>
              </div>
              <hr />
              <div class="text-center">
                <router-link style="background-color: #1c2243; color: var(--buttonBackgroundColor); width: 47%;"
                  class="btn" to="/my-account">
                  <img style="width: 50px;" src="/img/MPESA.png" />
                </router-link>
                <router-link
                  style="background-color: var(--buttonBackgroundColor); color: var(--darkBackground); margin-right: 5px; width: 47%;"
                  class="btn" to="/my-account">
                  <span style="font-size: 13px;">DEPOSIT</span>
                </router-link>
              </div>
              <hr />
              <div class="text-center">
                <router-link
                  style="background-color: #1c2243; color: var(--buttonBackgroundColor); width: 47%;text-align: left;"
                  class="btn" to="/my-account">
                  <img style="width: 50px;" src="/img/MPESA.png" />
                </router-link>
                <router-link
                  style="background-color: var(--buttonBackgroundColor); color: var(--darkBackground); margin-right: 5px; width: 47%;"
                  class="btn" to="/my-account">
                  <span style="font-size: 13px;">WITHDRAW</span>
                </router-link>
              </div>
              <hr />
              <h5>JACKPOT</h5>
              <router-link to="/gifts">
                <img style="width: 100%;border-radius: 7px;border: solid 1px var(--buttonBackgroundColor);" src="/img/daily_jackpot_banner.webp" />
              </router-link>
              <hr />
              <h5>DOWNLOAD APP</h5>
              <a href="/app/powerBet.apk">
                <img style="width: 100%;border-radius: 7px;border: solid 1px var(--buttonBackgroundColor);" src="/img/carousel/powerbetApp.jpeg" />
              </a>
              <hr />
              <h5>JOIN US</h5>
              <router-link to="/gifts">
                <img style="width: 100%;border-radius: 7px;border: solid 1px var(--buttonBackgroundColor);" src="/img/carousel/powerbetSignUp.jpeg" />
              </router-link>
              <hr />
            </div>
          </div>

          <!-- Main content -->
          <div class="col-lg-8 col-md-12">
            <div class="row">
              <div style="text-align: center;" v-for="(i, x) in allgamesdata" v-bind:key="getKey(x)"
                class="casino-game col-lg-3 col-md-3 col-sm-3 col-4 m-0 p-0">
                <div class="game-icon">
                  <img loading="lazy" :src="getImg(i.image)" alt="Avatar" data-toggle="modal"
                    :data-target="'#casinoGames' + i.game_id"
                    style="max-width: 100%; object-fit: contain; border-top-left-radius: 16px;border-top-right-radius: 16px; padding: 5px; border: solid var(--light-gray) 1px;" />
                  <p class="game-name">{{ i.game_name }}</p>
                </div>

                <!-- Modal -->
                <div class="modal fade" :id="'casinoGames' + i.game_id" tabindex="-1" role="dialog"
                  :aria-labelledby="'casinoGamesLabel' + i.game_id" aria-hidden="true">
                  <div class="modal-dialog modal-dialog" role="document">
                    <div style="color: var(--buttonBackgroundColor);" class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" :id="'casinoGamesLabel' + i.game_id">{{ i.game_name }}</h5>
                        <span style="color: var(--buttonBackgroundColor);" type="button" class="close" id="faso-close"
                          data-dismiss="modal">&times;</span>
                      </div>
                      <div class="modal-body">
                        <img loading="lazy" style="border-radius: 5px; border: solid var(--light-gray) 1px;"
                          :src="i.image" alt="gameimg" class="ww-100 mb-2" />
                        <div class="text-center">
                          <router-link data-dismiss="modal"
                            style="background-color: var(--buttonBackgroundColor); color: var(--darkBackground); margin-right: 5px; width: 47%;"
                            class="btn mb-2"
                            :to="{ name: 'casinogames', params: { IsDemo: 0, providerID: i.provider_id, gameName: i.game_name, gameID: i.game_id, launchURL: i.launch_url, launchType: i.launch_type, launchParameters: i.launch_parameters }, }">
                            Play Now
                          </router-link>
                          <router-link data-dismiss="modal"
                            style="background-color: #1c2243; color: var(--buttonBackgroundColor); width: 47%; border: solid 1px var(--buttonBackgroundColor);"
                            class="btn mb-2"
                            :to="{ name: 'casinogames', params: { IsDemo: 1, providerID: i.provider_id, gameName: i.game_name, gameID: i.game_id, launchURL: i.launch_url, launchType: i.launch_type, launchParameters: i.launch_parameters }, }">
                            Demo
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Right sidebar -->
          <div class="col-lg-2 d-none d-lg-block p-2">
            <div class="promotion-sidebar mb-2"
              style="background-color: #1c2243; color: white; padding: 10px; border-radius: 10px;height: 100%;">
              <h5>POLULAR GAMES</h5>
              <div v-for="(game, index) in homePageGame" v-bind:key="getKey(index)">
                <img loading="lazy"
                  style="max-width: 100%;object-fit: contain;border-radius: 16px;padding: 0;background-color: var(--lightest-gray);border: solid var(--light-gray) 1px;margin-bottom: 10px;"
                  v-bind:src="game.image" alt="Avatar" data-toggle="modal" v-bind:data-target="'#' + game.gameID" />
                <div class="modal fade" v-bind:id="game.gameID" tabindex="-1" role="dialog"
                  v-bind:aria-labelledby="game.gameID + 'Label'" aria-hidden="true">
                  <div class="modal-dialog modal-dialog" role="document">
                    <div style="color: var(--darkBackground);" class="modal-content">

                      <div class="modal-header">
                        <h5 style="color: var(--buttonBackgroundColor);" class="modal-title"
                          v-bind:id="game.gameID + 'Label'" v-text="game.gameName"></h5>
                        <span style="color: var(--buttonBackgroundColor);" type="button" class="close" id="faso-close"
                          data-dismiss="modal">&times;</span>
                      </div>

                      <div class="modal-body">
                        <img loading="lazy" style="border-radius: 5px;border: solid var(--light-gray) 1px;"
                          v-bind:src="game.image" alt="gameimg" class="ww-100 mb-2" />
                        <div class="text-center">

                          <router-link data-dismiss="modal"
                            style="background-color: var(--buttonBackgroundColor); color: var(--darkBackground); margin-right: 5px; width: 47%;"
                            class="btn mb-2"
                            :to="{ name: 'casinogames', params: { IsDemo: 0, providerID: game.providerID, gameName: game.gameName, gameID: game.gameID } }">
                            Play Now
                          </router-link>

                          <router-link data-dismiss="modal"
                            style="background-color: #1c2243; color: var(--buttonBackgroundColor); width: 47%; border: solid 1px var(--buttonBackgroundColor);"
                            class="btn mb-2"
                            :to="{ name: 'casinogames', params: { IsDemo: 1, providerID: game.providerID, gameName: game.gameName, gameID: game.gameID } }">
                            Demo
                          </router-link>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>

    </div>
  </div>
</template>
<style scoped>
.marquee-container {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  background-color: #1c2243;
  color: white;
  padding: 10px 0;
  box-sizing: border-box;
}

.marquee-text {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 20s linear infinite;
  /* Adjusted timing for smoothness */
}

.marquee-text span {
  margin-right: 50px;
  /* Adjust space between items */
}

@keyframes marquee {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(-100%);
  }
}

.game-name {
  background-color: #1c2243;
  width: 94%;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  text-align: center;
  padding: 5px;
  box-sizing: border-box;
  margin-top: -5px;
}

.game-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Center items horizontally */
  width: 100%;
}

.game-icon img {
  width: 100%;
  height: auto;
}

.modal-content {
  background-color: #1c2243;
}

.modal-body {
  background-color: #1c2243;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.category-tabs {
  max-width: calc(100% - 80px);
  /* Adjust the maximum width to leave space for the button */
  overflow-x: auto;
  /* Enable horizontal scrolling */
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
}

.pagination-btn {
  margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #f0f0f0;
  cursor: pointer;
}

.pagination-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination-num {
  color: white;
  font-weight: 600;
  padding: 3px;
}

.nav-link {
  color: white;
  cursor: pointer;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  z-index: 999;
}

.arrow.left {
  left: 10px;
}

.arrow.right {
  right: 10px;
}

.nav-link {
  background-color: #033E1F;
  color: #fff;
  border-radius: 5px;
  padding: 5px;
  margin-left: 2px;
}

.nav-tabs .nav-link.active {
  color: green !important;
  font-weight: 600;
  background-color: #f9b233;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .nav-link.hover {
  color: green !important;
  font-weight: 600;
  background-color: grey;
  border-color: #dee2e6 #dee2e6 #fff;
}

.carousel-container {
  overflow-x: auto;
}

.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.carousel-arrow-left {
  left: 0;
}

.carousel-arrow-right {
  right: 0;
}

a:active {
  color: green;
}

.games-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* Style for leaderboard section */
.leaderboard-container {
  margin-top: 20px;
}

/* Style for leaderboard list */
.leaderboard-list {
  list-style-type: none;
  padding: 0;
}

/* Style for leaderboard list item */
.leaderboard-item {
  margin-bottom: 5px;
}

/* Style for MSISDN, name, and bets */
.msisdn {
  font-weight: bold;
}

.name {
  margin-left: 5px;
}

.bets {
  margin-left: 5px;
}

/* Responsive styling */
@media only screen and (max-width: 100%) {

  /* Adjust font size for smaller screens */
  .msisdn,
  .name,
  .bets {
    font-size: 14px;
  }
}

@media only screen and (max-width: 576px) {

  /* Adjust margins for smaller screens */
  .name,
  .bets {
    margin-left: 0;
    margin-top: 5px;
    /* Add space between items */
  }
}


@media (min-width: 100%) {
  .game-card {
    flex: 0 0 calc(33.33% - 5px);
    /* 4 games per row with 20px margin between them */
  }
}

@media (max-width: 767.98px) {
  .game-card {
    flex: 0 0 calc(33.33% - 5px);
    /* 3 games per row with 20px margin between them */
  }
}

.game-card {
  position: relative;
}

.game-icon img {
  width: 100%;
  height: auto;
  /* Ensure image maintains aspect ratio */
  border-top-left-radius: 5px;
  /* Adjust border radius to match card */
  border-top-right-radius: 5px;
  /* Adjust border radius to match card */
}

.game-details {
  width: 100%;
}

.game-details p {
  margin-bottom: 5px;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.favorite-icon {
  position: absolute;
  top: 10px;
  /* Adjust top position as needed */
  right: 10px;
  /* Adjust right position as needed */
  font-size: 20px;
  /* Adjust font size as needed */
  color: red;
  /* Adjust color as needed */
  cursor: pointer;
}

.play-button {
  width: 100%;
  /* Fill the width */
  padding: 10px;
  /* Add padding as needed */
  background-color: #f9b233;
  /* Add desired background color */
  color: #033E1F;
  /* Add desired text color */
  font-weight: 600;
  border: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
}


.game-card:hover {
  transform: translateY(-5px);
}

.filter-dropdown select {
  appearance: none;
  /* Remove default appearance */
  background-color: #fff;
  /* Background color */
  border: 1px solid #ccc;
  /* Border */
  border-radius: 5px;
  /* Border radius */
  padding: 8px 12px;
  /* Padding */
  font-size: 11px;
  /* Font size */
  font-weight: 600;
  /* Font weight */
  cursor: pointer;
  /* Cursor on hover */
}

/* Dropdown arrow */
.filter-dropdown select::after {
  content: "\25BC";
  /* Unicode character for down arrow */
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  /* Prevents the arrow from being clickable */
}

/* Hover effect */
.filter-dropdown select:hover {
  border-color: #aaa;
  /* Border color on hover */
}

/* Focus effect */
.filter-dropdown select:focus {
  outline: none;
  /* Remove outline */
  border-color: #555;
  /* Border color on focus */
}

.filter-dropdown select option {
  background-color: #fff;
  /* Background color */
  color: #333;
  /* Text color */
  font-size: 11px;
  /* Font size */
}

.filter-dropdown select:disabled {
  opacity: 0.5;
  /* Reduced opacity */
  cursor: not-allowed;
  /* Disabled cursor */
}

.search-bar {
  position: relative;
}

.search-bar input[type="text"] {
  width: calc(100% - 30px);
  /* Subtracting the total padding (15px on each side) */
  padding: 8px 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 11px;
  outline: none;
}

.search-bar input[type="text"]:focus {
  border-color: #007bff;
}

.search-bar .search-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #ccc;
  cursor: pointer;
}

.search-bar .search-icon:hover {
  color: #007bff;
}

.custom-input {
  width: 100%;
  /* Adjusted width to match the search icon */
  padding: 6px 12px;
  /* Adjusted padding to match the filter button */
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 12px;
  outline: none;
}

.custom-input:focus {
  border-color: #007bff;
}

.custom-input::placeholder {
  color: #666;
  /* Placeholder text color */
  font-style: italic;
  /* Italicize placeholder text */
}

.BottomFooter {
  margin-top: 20px;
  /* Adjust this value as needed */
}

.vue-carousel-slide {
  padding: 10px;
}

/* Adjustments for responsiveness */
@media (max-width: 767.98px) {
  .vue-carousel-slide {
    padding: 0 10px;
    /* Adjust padding for smaller screens */
  }
}

/* Button styling */
.nav-link {
  display: block;
  padding: 5px 10px;
  margin-bottom: 5px;
  color: white !important;
  cursor: pointer;
}

.nav-link.active {
  background-color: #f9b233;
  font-weight: 600;
  color: white !important;
}

.nav-link:hover {
  background-color: #fff;
  font-weight: 600;
  color: green !important;
}

.tab-navigation {
  display: flex;
  justify-content: space-around;
}

.tab-navigation button {
  padding: 10px 20px;
  width: 100%;
  cursor: pointer;
  background-color: #f0f0f0;
  border: none;
  border-radius: 5px;
}

.tab-navigation button.active {
  background-color: green;
  color: white;
}

/* Adjustments for responsiveness */
@media (max-width: 767.98px) {
  .game-details {
    text-align: center;
    /* Center align the game details */
  }
}
</style>
<script>
import Vue from 'vue'
import ShimmerCasino from "./shimmer/ShimmerCasino.vue";
import ScrollLoader from 'vue-scroll-loader'
import casino from "@/services/casino";

const Slider = () => import('./Slider.vue');

Vue.use(ScrollLoader)
export default {
  name: "Casino",
  components: {
    Slider,
    ShimmerCasino
  },
  data: function () {
    return {
      match_id: 0,
      game_id: 0,
      fixture: {},
      initial_fixture: {},
      market_group: {
        id: 0,
      },
      totals: [],
      live: false,
      seconds: 0,
      possible_win: "",
      payout: "",
      tax: "",
      odd_id: "",
      outcome: {},
      msisdn: "",
      password: "",
      code: "",
      action_verify_password: false,
      action_success: false,
      message: "",
      allgamesdata: [],
      to_search: '',
      searchgamesdata: [],
      allgames: [],
      categories: [],
      casinocategories: [9, 4, 7, 12, 18, 17, 16],
      casinocategoriesObj: [
        {
          "id": 9,
          "title": "Popular"
        },
        {
          "id": 4,
          "title": "Slots"
        },
        // {
        //   "id" : 16,
        //   "title" : "Video Slots"
        // },
        // {
        //   "id" : 12,
        //   "title" : "Jackpot Games"
        // },
        {
          "id": 17,
          "title": "Classic Casino"
        },
        {
          "id": 8,
          "title": "Table Games"
        },
        // {
        //   "id" : 7,
        //   "title" : "Poker"
        // },
      ],
      casinoCategory: 9,
      casinoCategoryActive: {
        "id": 1,
        "name": "Crash"
      },
      casinoCategoryIndex: 0,
      disable: true,
      loading: false,
      touchstartX: 0,
      touchstartY: 0,
      touchendX: 0,
      touchendY: 0,
      currentPage: 1,
      perPage: 100,
      homePageGame: [
        {
          providerID: 7,
          gameID: "aviator",
          gameName: "Aviator",
          image: "/img/home/Aviator.jpg",
        },
        {
          providerID: 3,
          gameID: "1",
          gameName: "Comet",
          image: "/img/home/CometCrash.jpg",
        },
        {
          providerID: 5,
          gameID: "JetX",
          gameName: "Jet X",
          image: "/img/home/JetX.jpg",
        },
        {
          providerID: 5,
          gameID: "JetX3",
          gameName: "JetX3",
          image: "/img/home/JetX3.png",
        },
        // {
        //   providerID: 3,
        //   gameID: "3",
        //   gameName: "Paperplane",
        //   image: "/img/home/Mandege.png",
        // },
        // {
        //   providerID: 1,
        //   gameID: "vswaysbbb",
        //   gameName: "Big Bass Bonanza Megaways",
        //   image: "/img/home/Bass.png",
        // },
        // {
        //   providerID: 4,
        //   gameID: "rocketman",
        //   gameName: "Rocket Man",
        //   image: "/img/home/Rocketman.jpg",
        // },
        // {
        //   providerID: 3,
        //   gameID: "10",
        //   gameName: "Dare Devil",
        //   image: "/img/home/Daredevil.png",
        // },
        // {
        //   providerID: 3,
        //   gameID: "14",
        //   gameName: "Rugby Run",
        //   image: "/img/home/Rugby.png",
        // },
        // {
        //   providerID: 3,
        //   gameID: "4",
        //   gameName: "Taxi Ride",
        //   image: "/img/home/Taxi.png",
        // },
        // {
        //   providerID: 9,
        //   gameID: "cointoss",
        //   gameName: "Coin Toss",
        //   image: "/img/home/cointoss.png",
        // },
        // {
        //   providerID: 1,
        //   gameID: "vs5aztecgems",
        //   gameName: "Aztec Gems",
        //   image: "/img/home/Aztec.jpg",
        // },
        // {
        //   providerID: 9,
        //   gameID: "reelwheel",
        //   gameName: "Spin to Win",
        //   image: "/img/home/Spin2Win.jpg",
        // }
      ],
      categoryImages: [
        '/img/icons-star.png',
        '/img/icons-trophy.png',
        '/img/icons-clover.png',
        '/img/icons-spin.png',
        '/img/icons-coin.png',
        '/img/icons-cube.png',
        '/img/icons-casino.png',
        '/img/icons-diamond.png',
        '/img/icons-soccer.png',
        '/img/icons-game.png',
        '/img/icons-star.png',
        '/img/icons-trophy.png',
        '/img/icons-clover.png',
        '/img/icons-spin.png',
        '/img/icons-coin.png',
        '/img/icons-cube.png',
        '/img/icons-casino.png',
        '/img/icons-diamond.png',
        '/img/icons-soccer.png',
        '/img/icons-game.png',
        '/img/icons-casino.png',
        '/img/icons-diamond.png',
        '/img/icons-soccer.png',
        '/img/icons-game.png',
      ]
    };
  },

  mounted: function () {

    this.$store.dispatch("setCurrentPage", "casino");

    this.getCasinoGames(this.casinoCategoryActive);

    this.reloadProfile();

    var categories = [];

    var vm = this;

    this.jQuery.each(vm.games, function (k, v) {
      var key = v.Category.replaceAll(/ /g, "");
      var exists = false;

      // lets check if category exists
      vm.jQuery.each(categories, function (i, x) {

        if (x.key == key) {

          x.games.push(v);
          exists = true;
        }

        categories[i] = x;
      });

      if (!exists) {

        var gm = [];
        gm.push(v);

        var cat = {
          name: v.Category,
          key: key,
          games: gm,
        };

        categories.push(cat);
      }
    });

    this.groupedGames = categories;

    this.getCategories();

    this.showSlides();
    var touchableElement = document.getElementById('scroller');
    touchableElement.addEventListener('touchstart', function (event) {
      vm.touchstartX = event.changedTouches[0].screenX;
      vm.touchstartY = event.changedTouches[0].screenY;
    }, false);

    touchableElement.addEventListener('touchend', function (event) {
      vm.touchendX = event.changedTouches[0].screenX;
      vm.touchendY = event.changedTouches[0].screenY;
      vm.handleGesture();
    }, false);


  },

  computed: {
    app: function () {
      return this.$store.state.app;
    },

    profile: function () {
      return this.getProfile();
    },
    token: function () {
      return this.getAuth();
    },
  },

  methods: {
    handleGesture: function () {
      var scrollLeft = document.getElementById('catsec').scrollLeft
      if (this.touchendX < this.touchstartX && (this.touchstartX - this.touchendX) > 125) {
        console.log('Swiped Left');
        console.log("start: " + this.touchstartX + "end: " + this.touchendX)
        let index = this.casinocategoriesObj.indexOf(this.casinoCategoryActive);
        let count = this.casinocategoriesObj.length;
        let newIndex = 0
        if (index < 0) {
          newIndex = 1;
        } else {
          newIndex = index + 1;
        }
        console.log("Old index: " + index)
        if (newIndex <= count) {
          this.casinoCategoryActive = this.casinocategoriesObj[newIndex];
          this.getCasinoGames(this.casinoCategoryActive);

          document.getElementById('catsec').scrollLeft = scrollLeft + 50;
        }
      }

      if (this.touchendX > this.touchstartX && (this.touchendX - this.touchstartX) > 125) {
        console.log('Swiped Right');

        console.log("start: " + this.touchstartX + "end: " + this.touchendX)
        let index = this.casinocategoriesObj.indexOf(this.casinoCategoryActive);
        // let count = this.casinocategoriesObj.length;
        if ((index - 1) > -1) {
          this.casinoCategoryActive = this.casinocategoriesObj[(index - 1)];
          this.getCasinoGames(this.casinoCategoryActive);
          document.getElementById('catsec').scrollLeft = scrollLeft - 50;
        }

      }

      if (this.touchendY < this.touchstartY) {
        console.log('Swiped Up');
      }

      if (this.touchendY > this.touchstartY) {
        console.log('Swiped Down');
      }

      if (this.touchendY === this.touchstartY) {
        console.log('Tap');
      }
    },
    isActive: function (item) {
      // console.log(item)
      if (item.id == this.casinoCategoryActive.id) {
        return ' active';
      } else {
        return '';
      }
    },
    closeModal: function () {
      var docs = document.getElementsByClassName("modal-window")[0];
      docs.classList.add('d-none');
    },


    performSearch: function () {

      var keyword = this.to_search;
      var games = this.allgames;

      console.log(keyword);

      var results = games.filter(function (el) {

        console.log(el.game_name);
        var game_name = el.game_name.toLowerCase();

        return game_name.match(keyword.toLowerCase()) == null ? false : game_name.match(keyword.toLowerCase()).length > 0;

      });

      console.log('search results ' + JSON.stringify(this.searchgamesdata, undefined, 2))

      if (results.length > 0 && keyword !== "") {

        this.searchgamesdata = results;

      } else {

        this.searchgamesdata = [];

      }

    },

    keyUpSearch: function () {

      if (this.to_search == '') {

        this.searchgamesdata = [];

      }

    },

    loadPage: function (launch_url) {

      window.open(launch_url, '_blank');

    },

    getImg: function (img) {

      return img.replaceAll("/rec/325/", "/square/200/")

    },

    showSlides: function () {

      var vm = this;

      var slideIndex = 0;

      var i;
      var slides = document.getElementsByClassName("mySlides");
      var dots = document.getElementsByClassName("dot");

      for (i = 0; i < slides.length; i++) {

        slides[i].style.display = "none";
      }

      slideIndex++;

      if (slideIndex > slides.length) {

        slideIndex = 1;
      }

      for (i = 0; i < dots.length; i++) {

        dots[i].className = dots[i].className.replace(" active", "");

      }

      if (slides[slideIndex - 1]) {

        slides[slideIndex - 1].style.display = "block";
        dots[slideIndex - 1].className += " active";

      }

      setTimeout(function () {

        vm.showSlides();

      }, 2000)  // Change image every 2 seconds

    },

    getLaunchURL: function (launch_url) {

      var profile = this.getProfile();
      var tokn = profile.t1;

      // console.log('GOT launch_url '+launch_url);
      // console.log('GOT tokn '+tokn);

      launch_url = launch_url.replaceAll("profile_token", tokn);
      // console.log('GOT tokenized launch_url '+launch_url);

      return launch_url;

    },

    getKey: function (index) {

      return Math.random()
        .toString(10)
        .replace("0.", "games-id-" + index + "-");
    },

    getCasinoGames: function (activeCategory) {

      this.loading = true;
      this.casinoCategoryActive = activeCategory;

      var vm = this;

      //var platform = this.isMobile() ? 'mobile' : 'desktop';
      //var accountId = process.env.VUE_APP_CASINO_ACCOUNT_ID;

      var path = "/games?page=" + this.currentPage + "&per_page=" + this.perPage + "&category_id=" + activeCategory.id;

      casino.get(path)
        .then((resp) => {

          vm.allgamesdata = resp.data.data;
          vm.allgames = vm.allgamesdata;

          console.log("allgames --> " + JSON.stringify(vm.allgames, undefined, 2));

          this.loading = false;

          window.scrollTo(0, 0);

        });

    },

    getCategories: function () {

      var vm = this;

      var path = "/category";

      casino.get(path)
        .then(res => {

          vm.categories = res.data;

        })
        .catch(err => {

          if (err.response) {

            vm.setError("Failed", err.response.data.error_message)

          } else if (err.request) {

            console.log(JSON.stringify(err.request));

          } else {

            console.log(JSON.stringify(err));

          }
        })
    },

  },
  filters: {

    amount: function (val) {

      if (val === undefined) {
        return val;
      }

      return parseFloat(val).toFixed(2).toLocaleString();
    },
  },
};
</script>